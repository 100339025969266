/**
 * External dependencies.
 */
import YoutubePlayer from 'youtube-player';

/**
 * Get the YouTube video ID from a URL.
 *
 * @param {string} url - The YouTube video URL.
 * @returns {string|null} The YouTube video ID or null if not found.
 */
export const getYoutubeVideoID = ( url ) => {
	const regex = /[?&]v=([^&#]*)/;
	const match = url.match( regex );

	return match ? match[1] : null;
};

/**
 * Set the YouTube video.
 *
 * @param {string} videoUrl - The YouTube video URL.
 * @returns {void}
 */
export const setYoutubeVideo = ( videoUrl ) => {
	if ( !videoUrl ) {
		return;
	}

	const videoId = getYoutubeVideoID( videoUrl );

	if ( !videoId ) {
		console.error( 'Invalid YouTube video URL' );
		return;
	}

	window.AmeriProYTPlayer.loadVideoById( videoId );
};

/**
 * Initialize the YouTube player.
 */
const initYoutubePlayer = () => {
	const $player = $( '#player' );

	if ( !$player.length ) {
		return;
	}

	window.AmeriProYTPlayer = YoutubePlayer( $player[0], {
		playerVars: {
			rel: 0,
			showinfo: 0,
		}
	} );

	const videoUrl = $player.data( 'youtube-video-url' );
	const shouldAutoplay = $( '.section-article' ).length === 0;

	setYoutubeVideo( videoUrl );

	if ( shouldAutoplay ) {
		window.AmeriProYTPlayer.playVideo();
	} else {
		window.AmeriProYTPlayer.stopVideo();
	}
};

initYoutubePlayer();
