/**
 * Handles the intersection observer callback.
 *
 * @param {Array} entries
 * @param {IntersectionObserver} observer
 * @param {Number} delay
 */
const handleIntersection = ( entries, observer, delay = 0 ) => {
	setTimeout( () => {
		entries.forEach( ( entry ) => {
			if ( !entry.isIntersecting ) {
				return;
			}

			const { target } = entry;

			$( target ).addClass( 'is-animated' );
			observer.unobserve( target );
		} );
	}, delay );
};

/**
 * Creates and attaches an IntersectionObserver to an element.
 *
 * @param {Element} element
 * @returns {Void}
 */
const observeElement = ( element ) => {
	const $element = $( element );
	const delay = $element.data( 'animation-delay' ) ?? 0;

	const observer = new IntersectionObserver( ( entries ) => handleIntersection( entries, observer, delay ), {
		rootMargin: '0px 0px -5%',
	} );

	observer.observe( element );
};

/**
 * Gets the number of columns in a CSS Grid layout.
 *
 * @param {jQuery} $element The grid container element
 * @returns {Number} The number of columns in the grid
 */
const getGridColumnsCount = ( $element ) => {
	const gridTemplateColumns = window.getComputedStyle( $element[0] ).gridTemplateColumns;

	// If grid-template-columns is not set, return default of 3
	if ( !gridTemplateColumns || gridTemplateColumns === 'none' ) {
		return 3;
	}

	// Split the string into array and count the columns
	// Example: "100px 100px 100px" -> ['100px', '100px', '100px'] -> 3
	return gridTemplateColumns.split( ' ' ).length;
};

/**
 * Adds progressive animation delays to children elements, resetting the delay for each row in a grid.
 *
 * @param {jQuery} $element The parent element containing the grid items
 * @param {Number} columnsPerRow Number of columns in the grid (defaults to 3)
 */
const addDelayToChildren = ( $element, columnsPerRow = 3 ) => {
	$element.children().each( ( index, child ) => {
		const columnIndex = index % columnsPerRow;
		const delay = columnIndex * 300;

		$( child ).data( 'animation-delay', delay );
	} );
};

/**
 * Initializes the animations.
 *
 * @returns {Void}
 */
export const initAnimations = () => {
	// Exclude Gutenberg editor.
	if ( $( 'body' ).hasClass( 'block-editor-iframe__body' ) ) {
		return;
	}

	$( '[data-animation]' ).each( ( _, element ) => {
		const $parent = $( element ).parent();

		if ( $parent.hasClass( 'js-animation-delay-children' ) ) {
			const columnsCount = getGridColumnsCount( $parent );

			addDelayToChildren( $parent, columnsCount );
		}

		observeElement( element );
	} );
};

initAnimations();
