app.loadSwiper = async( callback ) => {
	const { Swiper } = await import( 'swiper' );
	const modules = await import( 'swiper/modules' );
	await import( 'swiper/css/bundle' );

	const defaultOptions = {
		speed: 1500,
	};

	callback( Swiper, modules, defaultOptions );
};
