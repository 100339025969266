import { initDefaultModals } from './modals';
import { getElementSize, doElementsOverlap } from '../utils/elements';

/**
 * Prepare a new button.
 *
 * @param {jQuery} $button - The button.
 * @returns {jQuery} The new button.
 */
const prepareNewButton = ( $button ) => {
	$button.show();

	const $newButton = $button.clone();
	const buttonPosition = $button.offset();

	const { top, left } = buttonPosition;
	const { width } = getElementSize( $button );

	$newButton.attr( {
		'data-position-top': top,
		'data-position-left': left,
	} );

	const isMobile = window.innerWidth <= 768;

	$newButton.css( {
		position: isMobile ? 'absolute' : 'fixed',
		'z-index': 98,
		top,
		left,
		width,
		'transition-property': 'top, left, opacity',
		'transition-duration': '0.3s',
	} );

	$newButton.addClass( ['callout-button', 'js-global-callout-button-duplicated', 'btn--not-full-width'] );

	$button.hide();

	return $newButton;
};

/**
 * Gets the button's position
 *
 * @param {jQuery} $button - The button.
 * @returns {Object} The position that the button should be located at. Top and left are being returned.
 */
const getButtonPosition = ( $button ) => {
	const BUTTON_OFFSET = 50;

	const isMobile = window.innerWidth <= 768;

	let top; let left;

	if ( isMobile ) {
		top = `${$button.attr( 'data-position-top' )}px`;
		left = `${$button.attr( 'data-position-left' )}px`;
	} else {
		top = window.innerHeight - $button.outerHeight() - BUTTON_OFFSET;
		left = window.innerWidth - $button.outerWidth() - BUTTON_OFFSET;

		if ( window.scrollY < 50 ) {
			top = `${$button.attr( 'data-position-top' )}px`;
			left = `${$button.attr( 'data-position-left' )}px`;
		}
	}

	return { top, left };
};

/**
 * Append a button to the page.
 *
 * @param {jQuery} $button - The button.
 */
const appendButton = ( $button ) => {
	if ( $button.length === 0 ) {
		return;
	}

	const $buttonParent = $button.parent();
	const buttonParentSize = getElementSize( $buttonParent );
	const $newButton = prepareNewButton( $button );

	$( 'body' ).append( $newButton );
	$buttonParent.css( buttonParentSize );
	initDefaultModals();
};

/**
 * Initialize the global callout button.
 */
appendButton( $( '.js-global-callout-button' ).eq( 0 ) );

$( window ).on( 'resize', () => {
	$( '.js-global-callout-button-duplicated' ).remove();
	appendButton( $( '.js-global-callout-button' ).eq( 0 ) );
} );

/**
 * Handle scroll.
 *
 * @returns {void}
 */
const handleScroll = () => {
	const $button = $( '.js-global-callout-button-duplicated' );
	const $footer = $( '.js-footer' );

	if ( $button.length === 0 ) {
		return;
	}

	const buttonPosition = getButtonPosition( $button );

	$button.css( buttonPosition );
	$button.toggleClass(
		'btn--transparent',
		doElementsOverlap( $button[0], $footer[0] ),
	);
};

/**
 * Handle window scroll.
 */
handleScroll();
$( window ).on( 'scroll', handleScroll );
