import { initSetWidthToButtons } from '../utils/buttons';

/**
 * Find the more content element.
 *
 * @param {jQuery} $button
 * @returns {jQuery}
 */
function findAdditionalContentElement( $button ) {
	return $button
		.closest( '.js-reveal-more-parent' )
		.find( '.js-reveal-more-content' );
}

/**
 * Handle reveal more click.
 *
 * @param {jQuery} $button
 * @returns {void}
 */
const handleRevealMoreClick = ( $button ) => {
	const $additionalContent = findAdditionalContentElement( $button );

	$additionalContent.slideToggle();

	$button.find( 'span' ).toggleClass( 'is-hidden' );
};

/**
 * Check if the additional content is empty.
 *
 * @param {jQuery} $additionalContent
 * @returns {boolean}
 */
const checkIfAdditionalContentIsEmpty = ( $additionalContent ) => {
	if ( $additionalContent.length === 0 ) {
		return true;
	}

	const $paragraphs = $additionalContent.find( 'p' );

	if ( $paragraphs.length !== 1 ) {
		return false;
	}

	const $paragraph = $paragraphs.first();

	return $paragraph.html().trim() === '<!--more-->';
};

/**
 * Initialize reveal more.
 *
 * @param {jQuery} $button
 * @returns {void}
 */
const initRevealMore = ( $button ) => {
	const $additionalContent = findAdditionalContentElement( $button );

	initSetWidthToButtons( $button );

	if ( checkIfAdditionalContentIsEmpty( $additionalContent ) ) {
		$button.hide();
	}

	$button.on( 'click', function( event ) {
		event.preventDefault();

		handleRevealMoreClick( $( this ) );
	} );
};

initRevealMore( $( '.js-reveal-more-trigger' ) );
