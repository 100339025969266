/**
 * Check if two elements overlap.
 *
 * @param {HTMLElement} element1 - The first element.
 * @param {HTMLElement} element2 - The second element.
 * @returns {boolean} True if the elements overlap, false otherwise.
 */
export const doElementsOverlap = ( element1, element2 ) => {
	const rect1 = element1.getBoundingClientRect();
	const rect2 = element2.getBoundingClientRect();

	const doNotOverlap =
		rect1.bottom < rect2.top ||
		rect1.top > rect2.bottom ||
		rect1.right < rect2.left ||
		rect1.left > rect2.right;

	return !doNotOverlap;
};

/**
 * Get the size of an element.
 *
 * @param {jQuery} $element - The element.
 * @returns {Object} The size of the element.
 */
export const getElementSize = ( $element ) => {
	return {
		width: $element.outerWidth(),
		height: $element.outerHeight(),
	};
};
