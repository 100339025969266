/**
 * Initialize gallery slider.

 * @param {jQuery} $slider
 * @param {number} initialSlide
 */
export const initGallerySlider = ( $slider, initialSlide = 0 ) => {
	app.loadSwiper( ( Swiper, modules ) => {
		$slider.each( ( _, slider ) => {
			new Swiper( slider, {
				modules: [ modules.Pagination ],
				centeredSlides: true,
				loop: true,
				pagination: {
					el: '.js-gallery-pagination',
					clickable: true,
				},
				initialSlide,
				breakpoints: {
					360: {
						slidesPerView: '1.6',
						loop: true,
					},
					1920: {
						slidesPerView: '1.8',
						loop: true,
					}
				}
			} );
		} );
	} );
};

/**
 * Get the gallery images from the parent element.
 *
 * @param {jQuery} $parent
 * @returns {Array} - Array of image objects. Contains `src` and `alt` properties.
 */
export const getGalleryImages = ( $parent ) => {
	const images = [];

	$parent.find( '.js-modal-btn-gallery' ).each( ( _, btn ) => {
		const $btn = $( btn );

		const src = $btn.attr( 'href' );
		const alt = $btn.find( 'img' ).attr( 'alt' );

		// Skip if image src already exists in array.
		if ( images.some( ( image ) => image.src === src ) ) {
			return;
		}

		images.push( {
			src,
			alt,
		} );
	} );

	return images;
};

/**
 * Append gallery images to the slider
 *
 * @param {jQuery} $sliderItems Slider items container
 * @param {Object} images Array of image objects. Contains `src` and `alt` properties.
 * @param {jQuery} $template Gallery item template
 */
export const appendGalleryImages = ( $sliderItems, images, $template ) => {
	const $items = images.map( ( image ) => {
		const $item = $template.clone();
		$item.find( 'img' ).attr( image );

		return $item;
	} );

	$sliderItems
		.empty()
		.append( $items );
};
