/**
 * Handle video trigger click.
 */
$( '.js-video-trigger' ).on( 'click', function( event ) {
	event.preventDefault();

	const $trigger = $( this );
	const $video = $trigger.closest( '.js-video' ).find( '.js-video-element' );

	$video.trigger( 'play' );
} );

/**
 * Handle video element play.
 */
$( '.js-video-element' ).on( 'play', function() {
	const $video = $( this );
	const $btn = $video.closest( '.js-video' ).find( '.js-video-trigger' );

	$video.attr( 'controls', true );
	$btn.addClass( 'is-hidden' );
} );
