import { closeAllModals } from './modals';
import { getFormHash, removeFormErrors } from '../utils/ninja-forms';

/**
 * Lock the HTML element when the modal is shown.
 *
 * @returns {void}
 */
const handleModalShow = () => {
	$( 'html' ).addClass( 'is-locked' );
};

/**
 * Unlock the HTML element when the modal is hidden.
 *
 * @returns {void}
 */
const handleModalHide = () => {
	$( 'html' ).removeClass( 'is-locked' );
};

/**
 * Check if any required fields are missing and return appropriate message.
 *
 * @param {Object} errors - The errors object containing field errors.
 * @returns {string} Required fields error message or empty string.
 */
const getRequiredMessage = ( errors ) => {
	const fields = errors.fields || {};

	return Object.values( fields ).some( field => field.message === 'This field is required.' )
		? 'Please fill all required fields.'
		: '';
};

/**
 * Check if error is from CleanTalk spam protection and return appropriate message.
 *
 * @param {string} responseErrorMessage - The error message from the response.
 * @param {jQuery} $modalMessageEntry - jQuery element containing cleantalk message.
 * @returns {string} CleanTalk error message or empty string
 */
const getCleantalkMessage = ( responseErrorMessage, $modalMessageEntry ) => {
	if ( !responseErrorMessage?.includes( 'CleanTalk' ) ) {
		return '';
	}

	return $modalMessageEntry.attr( 'data-cleantalk' ) || '';
};

/**
 * Get the error message from the response.
 *
 * @param {Object} response - The response object.
 * @param {jQuery} $modalMessageEntry - jQuery element containing cleantalk message.
 * @returns {string} Error message.
 */
const getErrorMessage = ( response, $modalMessageEntry ) => {
	const errors = response.response.errors;
	const fields = errors?.fields || {};
	const firstErrorKey = Object.keys( fields )[0];
	const firstErrorMessage = fields[firstErrorKey];

	let errorMessage = getRequiredMessage( errors );

	if ( !errorMessage && typeof firstErrorMessage === 'string' ) {
		errorMessage = getCleantalkMessage( firstErrorMessage, $modalMessageEntry );
	}

	return errorMessage || 'An error occurred. Please check the form.';
};

/**
 * Handle the error response from the form submission.
 *
 * @param {Object} response - The response object.
 * @returns {void}
 */
const handleError = ( response ) => {
	const $modalError = $( '#modal-error' );
	const $modalMessageEntry = $modalError.find( '.js-modal-message-entry' );

	const errorMessage = getErrorMessage( response, $modalMessageEntry );

	handleModalShow();
	closeAllModals();
	removeFormErrors();
	$modalError.modal( 'show' );
	$modalMessageEntry.html( errorMessage );
};

/**
 * Handle the success response from the form submission.
 *
 * @param {String} hash - The hash for the form.
 * @param {String} successMessage - The success message to display.
 * @returns {void}
 */
const handleSuccess = ( hash, successMessage ) => {
	const $modalSuccess = $( '#modal-success' );
	closeAllModals();
	const $modalMessageEntry = $modalSuccess.find( '.js-modal-message-entry' );

	$modalSuccess.on( 'shown.bs.modal', () => {
		handleModalShow();
	} );

	history.replaceState( null, document.title, location.pathname + location.search + `#${hash}` );
	$modalSuccess.modal( 'show' );
	$modalMessageEntry.html( successMessage );
};

/**
 * Handle the form submission response.
 */
$( document ).on( 'nfFormSubmitResponse', function( _, response ) {
	$( '#modal-form' ).modal( 'hide' );

	if ( Object.keys( response.response.errors ).length ) {
		handleError( response );
		return;
	}

	handleSuccess( getFormHash( response.id ), response.response.data.actions.success_message );
} );

/**
 * Handle thank you modal close.
 */
$( '#modal-success' ).on( 'hidden.bs.modal', () => {
	handleModalHide();
	history.replaceState( null, document.title, location.pathname + location.search );
} );
