/**
 * Remove the errors from the form fields.
 *
 * @returns {void}
 */
export const removeFormErrors = () => {
	let forms = nfRadio.channel( 'app' ).request( 'get:forms' );

	forms.each( function( form ) {
		let formFields = form.get( 'fields' );

		if ( !formFields ) {
			return;
		}

		formFields.models.forEach( fieldModel => {
			if ( fieldModel.get( 'errors' ).length ) {
				nfRadio.channel( 'fields' ).request( 'remove:error', fieldModel.get( 'id' ), 'required-error' );
			}
		} );
	} );
};

/**
 * Get the hash for the form based on the class name.
 *
 * @param {Number} formId - The ID of the form.
 * @returns {String}
 */
export const getFormHash = ( formId ) => {
	const $form = $( `#nf-form-${formId}-cont` );
	const classList = Array.from( $form[0].classList );
	const matchingClass = classList.find( ( className ) => className.startsWith( 'js-form-' ) );

	let hash = 'thank-you';

	if ( matchingClass ) {
		hash += `-${matchingClass.replace( 'js-form-', '' )}`;
	}

	return hash;
};
