/**
 * Internal dependencies.
 */
import { setYoutubeVideo } from './youtube';
import { appendGalleryImages, getGalleryImages, initGallerySlider } from './modal-gallery-slider';

/**
 * Close all modals.
 *
 * @returns {void}
 */
export const closeAllModals = () => {
	$( '.modal' ).modal( 'hide' );
};

/**
 * Handle the modal show event.
 *
 * @returns {void}
 */
const handleModalShow = () => {
	$( 'html' ).addClass( 'is-locked' );
};

/**
 * Handle the modal hide event.
 *
 * @returns {void}
 */
const handleModalHide = () => {
	$( 'html' ).removeClass( 'is-locked' );
};

/**
 * Handle modal trigger button clicks.
 *
 * @param {Event} event - The click event object
 * @param {jQuery} $modal - The modal element to show
 * @returns {void}
 */
const handleBtnClick = ( event, $modal ) => {
	event.preventDefault();
	$modal.modal( 'show' );
	$( 'body' ).css( 'overflow', 'unset' );
};

/**
 * Set up event handlers for a modal and its trigger button.
 *
 * @param {jQuery} $btn - The button element.
 * @param {jQuery} $modal - The modal element.
 * @param {Object} options - Optional event handlers.
 * @param {Function} options.onBtnClick - Button click handler.
 * @param {Function} options.onModalShow - Modal show handler.
 * @param {Function} options.onModalClose - Modal close handler.
 * @returns {void}
 */
const handleModalEvents = ( $btn, $modal, options = {} ) => {
	const { onBtnClick, onModalShow, onModalClose } = options;

	$btn.on( 'click', ( event ) => {
		handleBtnClick( event, $modal );
		onBtnClick?.( $modal );
	} );

	$modal.on( 'show.bs.modal', () => {
		handleModalShow();
		onModalShow?.( $modal );
	} );

	$modal.on( 'hidden.bs.modal', () => {
		handleModalHide();
		onModalClose?.( $modal );
	} );
};

/**
 * Handle the video modal close event.
 *
 * @param {jQuery} $modal - The video modal element
 * @returns {void}
 */
const handleVideoModalClose = ( $modal ) => {
	const $videoElement = $modal.find( '.js-video-element' );
	const $videoTrigger = $modal.find( '.js-video-trigger' );

	$videoElement.attr( 'src', '' );
	$videoTrigger.addClass( 'is-hidden' );
	window.AmeriProYTPlayer.stopVideo();
};

/**
 * Handle the video modal show event.
 *
 * @param {jQuery} $modal - The video modal element
 * @returns {void}
 */
const handleVideoModalShow = ( $modal ) => {
	const $videoTrigger = $modal.find( '.js-video-trigger' );

	$videoTrigger.removeClass( 'is-hidden' );
};

/**
 * Initialize default modals.
 *
 * @returns {void}
 */
export const initDefaultModals = () => {
	$( '.js-modal-btn' ).each( ( _, btn ) => {
		const $btn = $( btn );
		const $modal = $( $btn.attr( 'href' ) );

		handleModalEvents( $btn, $modal );
	} );
};

/**
 * Initialize HTML5 video modals.
 *
 * @returns {void}
 */
export const initVideoModals = () => {
	const $modal = $( '#modal-video' );

	$( '.js-modal-btn-video' ).each( ( _, btn ) => {
		const $btn = $( btn );

		handleModalEvents( $btn, $modal, {
			onBtnClick: ( $modal ) => {
				const $modalVideo = $modal.find( '.js-video-element' );

				$modalVideo.attr( {
					src: $btn.attr( 'href' ),
					poster: $btn.attr( 'data-poster-url' )
				} );
				$modalVideo.trigger( 'play' );
			},
			onModalShow: handleVideoModalShow,
			onModalClose: handleVideoModalClose,
		} );
	} );
};

/**
 * Initialize YouTube video modals.
 *
 * @returns {void}
 */
export const initVideoYoutubeModals = () => {
	const $modal = $( '#modal-video-youtube' );

	$( '.js-modal-btn-video-youtube' ).each( ( _, btn ) => {
		const $btn = $( btn );

		handleModalEvents( $btn, $modal, {
			onBtnClick: () => {
				const videoUrl = $btn.attr( 'href' );

				setYoutubeVideo( videoUrl );
			},
			onModalShow: handleVideoModalShow,
			onModalClose: handleVideoModalClose,
		} );
	} );
};

/**
 * Initialize image gallery modals.
 *
 * @returns {void}
 */
const initGalleryModals = () => {
	const $modal = $( '#modal-gallery' );
	const $modalGalleryInitialState = $modal.find( '.js-gallery' ).clone();
	const $galleryItemTemplate = $modal.find( '.js-gallery-item' ).clone();

	$( '.js-modal-btn-gallery' ).each( ( _, btn ) => {
		const $btn = $( btn );
		const slideIndex = $btn.data( 'slide' );

		handleModalEvents( $btn, $modal, {
			onBtnClick: ( $modal ) => {
				const $gallerySlider = $modal.find( '.js-gallery-slider' );
				const $gallerySliderItems = $gallerySlider.find( '.js-gallery-items' );
				const images = getGalleryImages( $btn.parent() );

				appendGalleryImages( $gallerySliderItems, images, $galleryItemTemplate );
				initGallerySlider( $gallerySlider, slideIndex );
			},
			onModalClose: ( $modal ) => {
				// Reset the slider's state to the initial one.
				$modal
					.find( '.js-gallery' )
					.html( $modalGalleryInitialState.html() );
			}
		} );
	} );
};

/**
 * Initialize all modal types.
 */
initVideoModals();
initDefaultModals();
initGalleryModals();
initVideoYoutubeModals();
