/**
 * Disable auto scroll on form submit.
 *
 * @returns {void}
 */
const disableAutoScrollOnSubmit = () => {
	if ( typeof Marionette === 'undefined' ) {
		return;
	}

	const SubmitController = Marionette.Object.extend( {
		initialize: function() {
			this.listenTo( nfRadio.channel( 'forms' ), 'submit:response', this.actionSubmit );
		},

		actionSubmit: function() {
			requestAnimationFrame( () => {
				$( 'html, body' ).stop( true, false );
			} );
		}
	} );

	new SubmitController();
};

disableAutoScrollOnSubmit();
